import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { colors } from "../styles/colors"
import { StyledLinkSpan } from "./../styles/global"
import { deviceMax, deviceMin } from "./../styles/mediaqueries"
import { fontweight } from "../styles/variables"
import LogoHorisontalBlue from "./Illustrations/LogoHorisontalBlue"
import LogoHorisontalWhite from "./Illustrations/LogoHorisontalWhite"
import { ILocation } from "../interface/location"

interface LogoProps {
    horizontal?: any
}

const StyledLogo = styled.div<LogoProps>`
    width: 185px;
    @media ${deviceMax.mobileL}, ${deviceMax.mobileM}, ${deviceMax.mobileS} {
        width: 150px;
    }
`

const StyledNav = styled.nav<{ hero: boolean }>`
    &.navbar {
        background: ${(props) =>
            props.hero ? colors.darkBlue : colors.lightBlue} !important;
        transition: background-color 0.2s ease-in-out;

        &.is-transparent {
            background-color: transparent;
            background-image: none;
        }
    }
`

const StyledNavbarBrand = styled.div`
    &.navbar-brand {
        padding: 0.5rem 0.75rem;
        margin: 15px;
        @media ${deviceMax.mobileL},
            ${deviceMax.mobileM},
            ${deviceMax.mobileS} {
            margin: 5px;
            padding: 1rem 10px;
        }
    }
    flex: 1;
`

const StyledNavbarBurger = styled.a<{ hero?: any }>`
    &.navbar-burger {
        border: none;
        background: ${(props) => (props.hero ? colors.darkBlue : "inherit")};
        color: ${(props) => (props.hero ? colors.white : colors.darkBlue)};
        margin-right: 3px;
        margin-top: 4px;

        &:active {
            color: ${colors.xlightBlue};
            background: ${colors.lightBlue};
            border-radius: 100%;
            transition: 0.5s ease-in;
        }
        &:hover {
            background: none;
        }
        &:focus {
            outline: 1px solid black;
        }
    }
`

const StyledNavbarMenu = styled.div<{ hero: any }>`
    .navbar-menu {
        & .is-active {
            background-color: ${(props) =>
                props.hero ? colors.darkBlue : colors.lightBlue};
            @media ${deviceMax.laptop}, ${deviceMin.laptop} {
                background-color: ${(props) =>
                    props.hero ? colors.darkBlue : colors.lightBlue} !important;
            }
        }
    }
    > .navbar-end {
        > a.navbar-item {
            & > span {
                color: ${(props) =>
                    props.hero ? colors.white : colors.darkBlue};
                font-weight: ${fontweight.semibold};
                letter-spacing: 1px;
            }
        }

        .navbar-link.is-active,
        .navbar-link:focus,
        .navbar-link:focus-within,
        .navbar-link:hover,
        a.navbar-item a.navbar-item.is-active,
        a.navbar-item:focus,
        a.navbar-item:focus-within,
        a.navbar-item:hover {
            background-color: ${colors.xlightBlue};
        }
    }
`

const StyledNavWrapper = styled.div`
    display: flex;
    flex: 1;
    max-width: 1450px;
    margin: 0 auto;
    padding: 0 16px;
    @media ${deviceMax.laptop} {
        flex-direction: column;
    }
    @media ${deviceMax.mobileL} {
        padding: 0 5px;
    }
`

const AnchorWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: ${fontweight.semibold};
`

const Nav: React.FC<{ location: ILocation; hero?: boolean }> = ({
    location,
    hero,
}) => {
    const [menuActive, setMenuActive] = useState(false)
    const [currentLocation, setCurrentLocation] = useState("")

    useEffect(() => {
        let mounted = true
        if (!currentLocation.length) {
            if (mounted && location) {
                setCurrentLocation(location.pathname)
            }
        }
        return () => {
            mounted = false
        }
    }, [currentLocation])

    const handleActiveMenuClick = (e: any) => {
        if (e) {
            setMenuActive(false)
        }
    }

    const handleToggleMenu = () => {
        setMenuActive(!menuActive ? true : false)
    }

    return (
        <StyledNav className={`navbar is-fixed-top`} hero={hero ? true : false}>
            <StyledNavWrapper>
                <StyledNavbarBrand className="navbar-brand">
                    <StyledLogo horizontal>
                        <AnchorLink to="/#header" stripHash={true}>
                            {hero ? (
                                <LogoHorisontalWhite
                                    width="100%"
                                    height="100%"
                                />
                            ) : (
                                <LogoHorisontalBlue
                                    width="100%"
                                    height="100%"
                                />
                            )}
                        </AnchorLink>
                    </StyledLogo>
                    <StyledNavbarBurger
                        role="button"
                        className={`navbar-burger ${
                            menuActive ? "is-active" : ""
                        }`}
                        onClick={handleToggleMenu}
                        aria-label="menu"
                        aria-expanded={menuActive ? "true" : "false"}
                        data-target="navbarExpand"
                        hero={hero}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </StyledNavbarBurger>
                </StyledNavbarBrand>
                <StyledNavbarMenu
                    id="navbarExpand"
                    className={`navbar-menu ${menuActive ? "is-active" : ""}`}
                    style={{
                        background: `${
                            hero ? colors.darkBlue : colors.lightBlue
                        }`,
                    }}
                    hero={hero}
                >
                    <div className="navbar-end">
                        <AnchorWrapper
                            onClick={(e) => handleActiveMenuClick(e)}
                        >
                            <AnchorLink
                                to="/#omoss"
                                stripHash={true}
                                className={`navbar-item menu-item ${
                                    currentLocation === "/omoss/"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <StyledLinkSpan hero={hero}>
                                    Om oss
                                </StyledLinkSpan>
                            </AnchorLink>
                        </AnchorWrapper>
                        <AnchorWrapper
                            onClick={(e) => handleActiveMenuClick(e)}
                        >
                            <AnchorLink
                                to="/#tjenester"
                                stripHash={true}
                                className={`navbar-item menu-item ${
                                    currentLocation === "/tjenester/"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <StyledLinkSpan hero={hero}>
                                    Våre tjenester
                                </StyledLinkSpan>
                            </AnchorLink>
                        </AnchorWrapper>
                        <AnchorWrapper
                            onClick={(e) => handleActiveMenuClick(e)}
                        >
                            <AnchorLink
                                to="/#kontakt"
                                stripHash={true}
                                className={`navbar-item menu-item ${
                                    currentLocation === "/kontakt/"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <StyledLinkSpan hero={hero}>
                                    Kontakt
                                </StyledLinkSpan>
                            </AnchorLink>
                        </AnchorWrapper>

                        <AnchorWrapper
                            onClick={(e) => handleActiveMenuClick(e)}
                        >
                            <a
                                href="https://bblprod.service-now.com/sp"
                                className="navbar-item menu-item"
                                onClick={handleActiveMenuClick}
                            >
                                <StyledLinkSpan hero={hero}>
                                    Kundestøtte
                                </StyledLinkSpan>
                            </a>
                        </AnchorWrapper>
{/* 
                        <AnchorWrapper
                            onClick={(e) => handleActiveMenuClick(e)}
                        >
                            <a
                                href="/aapenhetsloven"
                                className="navbar-item menu-item"
                                onClick={handleActiveMenuClick}
                            >
                                <StyledLinkSpan hero={hero}>
                                    Åpenhetsloven
                                </StyledLinkSpan>
                            </a>
                        </AnchorWrapper> */}

                        {/*<AnchorWrapper onClick={(e) => handleActiveMenuClick(e)}>
                            <AnchorLink
                                to="/#news"
                                stripHash={true}
                                className={`navbar-item menu-item ${
                                    currentLocation === "/news/" ? "active" : ""
                                }`} >
                                <StyledLinkSpan hero={hero}>
                                    Nyheter
                                </StyledLinkSpan>
                            </AnchorLink>
                        </AnchorWrapper>*/}
                    </div>
                </StyledNavbarMenu>
            </StyledNavWrapper>
        </StyledNav>
    )
}

export default Nav
