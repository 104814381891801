export const size = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    mobileHorizontal: "812px",
    tablet: "768px",
    laptopNarrow: "1000px",
    laptop: "1023px",
    laptopL: "1440px",
    desktop: "2560px",
}

export const deviceMax = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    mobileHorizontal: `(max-width: ${size.mobileHorizontal})`,
    tablet: `(max-width: ${size.tablet})`,
    laptopNarrow: `(max-width: ${size.laptopNarrow})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
}

export const deviceMin = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
}
