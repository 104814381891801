import { IColors } from "../interface/style-types"

export const colors: IColors = {
    text: "#131e46",
    textBlue: "#739AEE",
    textMediumBlue: "#10105ab8",
    textLighterBlue: "#ABC3F5",
    linkSpan: "#131e46",
    linkSpanHover: "#131e46",
    darkBlue: "#131E46",
    royalBlue: '#10105A',
    xDarkBlue: "#131E46",
    skyBlue: "#ABC3F5",
    lightSkyBlue: "#E8EEFC",
    lightBlue: "#eef4fb",
    xlightBlue: "#0057b700",
    blue: "#1657e2",
    backgroundBlurBorder: "#8e8e8e",
    white: "#FFFFFF",
}

