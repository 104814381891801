/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { Config } from "../config/index"
import { IConfig } from "../interface/semantics"

interface Props {
    description?: string
    lang?: string
    meta?: any
    title?: string
}

function SEO({ description, lang, meta, title }: Props) {
    const { site, metalogo } = useStaticQuery(
        graphql`
            query {
                site: site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
                metalogo: allImageSharp(
                    filter: {
                        resize: {
                            originalName: {
                                eq: "bbl-pivotal-horisontal-blaa.png"
                            }
                        }
                    }
                ) {
                    edges {
                        node {
                            resize(width: 500) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        `
    )

    const { src, height, width } = metalogo.edges[0].node.resize

    const conf: IConfig = Config

    const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: conf.siteKeywords,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:image`,
                    content: src,
                },
                {
                    property: `og:image:height`,
                    content: height,
                },
                {
                    property: `og:image:width`,
                    content: width,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: conf.siteURL,
                },
                {
                    property: `og:locale`,
                    content: conf.siteLanguage,
                },
                {
                    property: `og:site_name`,
                    content: conf.siteTitle,
                },
                {
                    name: `schema:legalName`,
                    content: conf.slegalname,
                },
                {
                    name: `schema:location`,
                    content: conf.slocation,
                },
                {
                    name: `schema:address`,
                    content: conf.saddress,
                },
                {
                    name: `schema:addressCity`,
                    content: conf.saddressCity,
                },
                {
                    name: `schema:openingHours`,
                    content: conf.sOpeningHours,
                },
                {},
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
