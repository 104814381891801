import { IConfig } from "../interface/semantics";

export const Config: IConfig = {
    siteTitle: 'BBL Pivotal',
    siteDescription: 'BBL Pivotal digitaliserer boligforvaltningen gjennom bransjeledende kompetanse, innovasjon og brukervennlige løsninger.',
    siteKeywords: 'bbl pivotal, bbl digital, bbl partner, bbl, bbl datakompetanse, boligbyggelag, boligforvaltning, hårfagre.net, hårfagre, portal, minside, meglerportal, forsikring, rpa, maskinlaering, bblid, medlemsfordeler, medlemskort, it-drift',
    siteURL: 'https://bblpivotal.no',
    siteLanguage: 'nb_NO',
    location: 'Oslo, NO',
    saddressCity: 'Oslo',
    sOpeningHours: '07:00 - 17:00',
    saddress: 'Akersgata 35/39, 0158 Oslo',
    slegalname: 'BBL Datakompetanse AS',
    slocation: 'Norway',
    
}
