import { useState, useEffect } from "react"
import { ILocation } from "./../interface/location"

function useTranslationName(origin: ILocation): string {
    let [locationName, setLocationName] = useState<string>("")
    let location: string = origin.pathname
    location = location.replace(/\//g, "")
    useEffect(() => {
        let timeoutId: any = null
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            switch (location) {
                case "omoss":
                    setLocationName("Om oss")
                    break
                case "tjenester":
                    setLocationName("Tjenester")
                    break
                case "kundestotte":
                    setLocationName("Kundestøtte")
                    break
                case "kontakt":
                    setLocationName("Kontakt")
                    break
                case "pressemelding":
                    setLocationName("Pressemelding")
                    break
                case "totalforsikring":
                    setLocationName("Totalforsikring")
                    break
                case "forsikring":
                    setLocationName("Forsikring")
                    break
                case "medlemsfordeler":
                    setLocationName("Medlemsfordeler")
                    break
                case "Pensjonsogpersonalforsikring":
                    setLocationName("Pensjons- og personalforsikring")
                    break
                case "eierskifteforsikring":
                    setLocationName("Eierskifteforsikring")
                    break
                case "forsikringogskadevern":
                    setLocationName("Forsikring og skadevern")
                    break
                case "forsikringogskadevernforsikring":
                    setLocationName("Forsikring")
                    break
                case "forsikringogskadeverntotalforsikring":
                    setLocationName("Totalforsikring")
                    break
                case "forsikringogskadevernpensjonforsikring":
                    setLocationName("Pensjon- og personalforsikring")
                    break
                case "forsikringogskadeverneierskifteforsikring":
                    setLocationName("Eierskifteforsikring")
                    break
                case "forsikringogskadevernsikring-mot-brann":
                    setLocationName("Sikring mot brann")
                    break
                case "forsikringogskadevernsikring-mot-vann":
                    setLocationName("Sikring for vannskader")
                    break
                case "forsikringogskadevernskadevern":
                    setLocationName("Skadevern")
                    break
                case "forretningsforsel":
                    setLocationName("Forretningsførsel")
                    break
                case "digitalarbeidsflate":
                    setLocationName("Digital arbeidsflate")
                    break
                case "ansatte":
                    setLocationName("Ansatte")
                    break
            }
        }, 150)
    })
    return locationName
}

export default useTranslationName
