import styled from "styled-components"
import {
    IStyledOverlay,
    IStyledSectionContentProps,
    IStyledContainerProps,
} from "./../interface/style-types"
import { colors } from "./colors"
import React from "react"
import { deviceMax, size } from "./mediaqueries"
import { GatsbyImage } from "gatsby-plugin-image";
import { fontweight, containerWidth } from "./variables"

export const layout = {
    padding: "0 16px",
    margin: "0 auto",
    maxWidth: "1140px",
}

export const StyledOverlay = styled.div<IStyledOverlay>`
    position: absolute;
    width: 100vw;
    height: inherit;
    background: #f3f3f3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-flow: wrap;
    padding: 20px;
    opacity: 0.8;
`

export const StyledOuterWrapper = styled.div<{
    footer?: boolean
    frontpage?: boolean
}>`
    max-width: ${containerWidth.width};
    overflow: hidden;
    margin: ${props =>
        props.frontpage
            ? `${props.footer ? "0px auto" : "100px auto"}`
            : `${props.footer ? "0px auto" : "25px auto"}`};
`

export const StyledLinkSpan = styled.span<{ large?: any; hero?: any }>`
    transition: all 0.2s ease;
    color: ${props => (props.hero ? colors.white : colors.linkSpan)};
    &:hover {
        box-shadow: inset 0 ${props => (props.large ? "-5px" : "-3px")} 0
            ${props => (props.hero ? colors.white : colors.linkSpanHover)};
    }
`

export const StyledContainer = styled.div<IStyledContainerProps>`
    ${props =>
        props.frontpage &&
        `
        @media ${deviceMax.mobileL} {
          max-width: unset;
          padding: unset;
          margin: unset; 
        } 
        @media ${deviceMax.mobileS} {
          max-width: unset;
          padding: unset;
          margin: unset; 
        }
        @media ${deviceMax.mobileM} {
          max-width: unset;
          padding: unset;
          margin: unset; 
        } 
    `}
    ${props =>
        props.wide &&
        `
        max-width: 1450px;
    `}
    ${props => props.fullwidth && `
        width: 100vw;
        padding: 0;
        margin-bottom: 5rem;
    `}
`
export const StyledHeadline = styled.div<{ reverse?: any; ingress?: any }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => (props.ingress ? colors.textBlue : colors.royalBlue)};
    & > .bbl-title-small {
        color: ${props => (props.ingress ? colors.white : colors.blue)};
        font-size: 16px;
        font-weight: ${fontweight.bold};
        padding-bottom: 1rem;
        line-height: 20px;
    }

    & > .bbl-title {
        color: ${props => (props.ingress ? colors.textBlue : colors.royalBlue)};
        font-size: 34px;
        font-weight: ${fontweight.semibold};
        padding: 1rem 0;
        max-width: 900px;
        @media ${deviceMax.mobileL} {
            font-size: 30px;
            line-height: 35px;
        }
        @media ${deviceMax.mobileM} {
            font-size: 26px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 26px;
            line-height: 36px;
        }
    }

    & .bbl-regular {    
        font-size: 18px;
        line-height: 27px;
        color: ${colors.royalBlue}
    }

    & > .bbl-subtitle {
        color: ${props =>
            props.ingress ? colors.textLighterBlue : colors.royalBlue};
        font-size: 35px;
        font-weight: ${fontweight.semibold};
        max-width: 900px;
        @media ${deviceMax.mobileL} {
            font-size: 23px;
            line-height: 35px;
        }
        @media ${deviceMax.mobileS} {
            font-size: 25px;
            line-height: 33px;
        }
    }

    @media ${deviceMax.mobileL}, ${deviceMax.mobileM} {
        text-align: center;
    }

    > h2 {
        font-size: 30px;
        font-weight: ${fontweight.semibold};
        @media ${deviceMax.desktop},
            ${deviceMax.laptop},
            ${deviceMax.tablet},
            ${deviceMax.mobileM} {
            font-size: 30px;
        }
        @media ${deviceMax.mobileL} {
            font-size: 25px;
        }
    }

    justify-content: ${props => props.reverse && "flex-end"};
`
export const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
	margin-bottom: 0.5rem;
`
export const StyledIllustration = styled(props => <GatsbyImage {...props} />)`
    position: relative;
    top: -3rem;
    width: 31rem;
    @media ${deviceMax.laptop}, ${deviceMax.tablet} {
        width: 31rem;
    }
    @media ${deviceMax.mobileL} {
        width: 23rem;
    }
    @media ${deviceMax.mobileM} {
        width: 21rem;
    }
    @media ${deviceMax.mobileS} {
        width: 19rem;
    }
`

export const StyledGrid = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 50px 0;
`

export const StyledFullheightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
`

export const StyledImage = styled(props => <GatsbyImage {...props} />)`
    border-radius: 10px;
    box-shadow: 1px 5px 12px 8px rgba(232, 232, 232, 0.02),
        -4px 7px 22px -6px rgba(0, 0, 0, 0.1),
        13px 14px 35px -19px rgba(0, 0, 0, 0.2);
    &.contact {
        position: relative;
        min-height: calc(30vh - 0rem);
        min-width: calc(50vh - 20rem);
        max-height: 20rem;
        right: 5rem;
        box-shadow: none;
        @media ${deviceMax.laptop},
            ${deviceMax.tablet},
            ${deviceMax.mobileL},
            ${deviceMax.mobileL},
            ${deviceMax.mobileS} {
            right: 0rem;
        }
        &.slim {
            min-height: unset;
        }
    }
`

export const StyledSectionWrapper = styled.div<{element?: any}>`
    padding: 0rem;
    ${props => props.element && 'margin: 50px 0'};
`
export const StyledCardGridWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
export const StyledSectionContent = styled.div<IStyledSectionContentProps>`
    font-size: ${props => props.text && "19px"};
    ${props =>
        props.media &&
        `
      align-self: center;
      margin: 20px;
	  @media ${deviceMax.mobileL},
            ${deviceMax.mobileL},
            ${deviceMax.mobileS} {
			margin: 20px 20px 60px 20px;
        }   
    `}
    ${props =>
        props.hasSubtitle &&
        `
        flex-direction: column;
        justify-content: start;
        align-items: start;
        & > h2 {
            margin-left: 2.60rem;
            font-weight: ${fontweight.semibold};
            font-size: 25px;
        }
        
    `}
`

export const StyledPageWrapper = styled.div`
    margin: 10rem 0;
`

export const StyledImageText = styled.div<{small?: any}>`
    height: 40px;
    width: auto;
    text-align: center;

    line-height: 70px;
    & > a {
        color: #131e46;
        transition: all 1s ease;
        font-size: 30px;
        @media ${deviceMax.mobileM} {
            font-size: 27px;
        }
    }

    & > p {
        transition: text-shadow 0.2s ease-in-out;
        color: ${colors.royalBlue};
        font-size: ${props => props.small ? '24px' : '31px' };
        @media ${deviceMax.mobileM} {
            font-size: ${props => props.small ? '22px' : '27px' };;
        }
        @media ${deviceMax.mobileS} {
            font-size: ${props => props.small ? '20px' : '24px' };;
        }
    }
`

export const StyledButton = styled.button`
    border-radius: 30px;
    padding: 1rem 2rem;
    color: ${colors.blue};
    font-weight: ${fontweight.semibold};
    background: ${colors.white};
    font-size: 16px;
    width: 192px;
    box-shadow: none;
    border: none;
    outline: none;
    z-index: 10;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    :hover {
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    }
`
export const StyledSectionHeaderContainer = styled.div`
    padding: 2rem 0;
`
