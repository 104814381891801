import React, { Fragment } from "react"
import PropTypes from "prop-types"
import "../styles/layout.scss"
import Nav from "./nav"
import SEO from "./seo"
import { Footer } from "./footer"
import styled from "styled-components"
import { StyledOuterWrapper } from "../styles/global"
import useTranslationName from "../hooks/useTranslationName"
import HeroIngress from "./heroIngress"
import { ILocation } from "./../interface/location"

interface ILayoutProps {
    location?: ILocation
    children?: any
    logo?: any
    hero?: boolean
    ingress?: any
}

const StyledRoot = styled.div`
    background-color: #eef4fb;
`

const Layout: React.FC<ILayoutProps> = ({
    location,
    children,
    hero,
    ingress,
}: any) => {
    const locationName = useTranslationName(location)
    const isHome = location?.pathname === "/"

    return (
        <StyledRoot id="root">
            <SEO title={isHome ? "Hjem" : locationName} />
            {hero ? (
                <Fragment>
                    <HeroIngress location={location} ingress={ingress} />
                </Fragment>
            ) : (
                <Nav location={location} />
            )}

            {isHome ? (
                <main id="content">{children}</main>
            ) : (
                <StyledOuterWrapper>
                    <main id="content">{children}</main>
                </StyledOuterWrapper>
            )}

            <Footer />
        </StyledRoot>
    )
}

export default Layout
