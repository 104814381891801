import React, { Fragment } from "react"
import Nav from "./nav"
import { colors } from "../styles/colors"
import styled from "styled-components"
import { StyledHeadline } from "../styles/global"
import { deviceMax } from "./../styles/mediaqueries"
import { ILocation } from "./../interface/location"

const StyledHeroBody = styled.div`
    background-color: ${colors.darkBlue};
    @media ${deviceMax.tablet} {
        margin-top: 5rem;
    }
`

const HeroIngress: React.FC<{ location: ILocation; ingress: any }> = ({
    location,
    ingress,
}) => {
    return (
        <Fragment>
            <section className="hero is-medium">
                <div
                    className="hero-head"
                    style={{ backgroundColor: colors.darkBlue }}
                >
                    <Nav location={location} hero={true} />
                </div>
                <StyledHeroBody className="hero-body">
                    <div className="container has-text-centered">
                        <StyledHeadline ingress>
                            <h4 className="bbl-title-small">
                                {ingress?.pagename?.toUpperCase()}
                            </h4>
                            <h6 className="bbl-subtitle">{ingress?.ingress}</h6>
                        </StyledHeadline>
                    </div>
                </StyledHeroBody>
            </section>
        </Fragment>
    )
}

export default HeroIngress
